import { serialize } from "cookie-es";
import { v4 as uuidv4 } from "uuid";
import { DeviceType, useCreateDeviceMutation } from "~/graphql/generated";

// Expires 400 days from now (max for cookie expiration) https://developer.chrome.com/blog/cookie-max-age-expires/#:~:text=this%20limit%20added%3F-,As%20of%20Chrome%20release%20M104%20(August%202022)%20cookies%20can%20no,when%20the%20browsing%20session%20ends.
const expires = new Date();
expires.setDate(expires.getDate() + 400);

const DEVICE_ID = "deviceid";
const DEVICE_GUID = "deviceguid";

export function useDeviceId() {
  const cookie = useCookie<string>(DEVICE_ID, { expires });

  // Check if we have a deviceid stored already
  if (cookie.value) {
    // Update the max expiration time
    // https://github.com/nuxt/nuxt/issues/20652
    if (process.client) {
      document.cookie = serialize(DEVICE_ID, cookie.value, { expires });
    }

    return cookie;
  }

  // If not, generate a new deviceid (guid)
  // We use useState to prevent race conditions if multiple components on the same page call this function, at least they both use the same guid
  const guid = useState<string>(DEVICE_GUID, () => uuidv4());

  // Store it in the server with the createDevice mutation
  const createDevice = useCreateDeviceMutation();

  createDevice.executeMutation({
    createDevice: {
      type: DeviceType.Web,
      uid: guid.value,
    },
  });

  // Save it to the cookie
  cookie.value = guid.value;

  // Return the deviceid
  return cookie;
}
